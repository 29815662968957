<template>
  <v-app>
    <v-main>
      <v-container>
        <a href="/user">adminログインはこちら</a>
        <br />
        <br />
        <a href="/customer">企業ログインはこちら</a>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
};
</script>
